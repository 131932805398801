import React, { FC } from "react";
import Layout from "../components/Layout";
import { Routes } from "../constants/routes";
import SEO from "../components/Seo";
import useMarkdown from "../hooks/useMarkdown";
import styled from "styled-components";

const Content = styled.div`
  padding: var(--s12);
  a,
  a:visited,
  a:hover {
    color: currentColor;
  }
  p {
    max-width: 80ch;
  }

  div {
    padding-bottom: var(--s8);
  }

  div > p {
    margin-top: var(--s10);
  }

  p ~ ul {
    margin-top: var(--s24);
  }

  ul + p {
    margin-top: var(--s24);
  }

  h2 {
    margin-bottom: var(--s4);
    margin-top: var(--s12);
    font-size: 2rem;
    line-height: 3.3rem;
    @media screen and (max-width: 700px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
  @media screen and (max-width: 700px) {
    padding: var(--s4);
  }
  a {
    text-decoration: underline;
    font-size: 2rem;
    line-height: 3.3rem;
    @media (max-width: 700px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
  li,
  p {
    font-weight: 300;
    font-size: 2rem;
    line-height: 3.3rem;
    @media (max-width: 700px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  > div > ul {
    max-width: 1500px;
    > li {
      display: grid;
      grid-template-columns: 1fr 3fr;
      border-top: 1px solid var(--grey);
      padding: var(--s2) 0;
      ul {
        margin-top: 0;
      }
    }
  }
`;

const BiografiePage: FC = () => {
  const { html } = useMarkdown("biografie")[0];

  return (
    <Layout activePage={Routes.BIOGRAFIE}>
      <SEO title="Biografie" />
      <Content>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Content>
    </Layout>
  );
};

export default BiografiePage;
